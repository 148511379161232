import React from 'react';
import '../assets/stylesheets/deep.css';
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import { Link } from "react-router-dom";

const  Deep = () => {
    return (
      <div id="deepcontainer">

            <section id="deepbanner">

            

            </section>
            <section className="opensection">
                <section className="opencontent">
                    <h3 className="sectiontitle"> Deep Cleaning </h3>
                    <p>
                    Deep cleaning services refer to a thorough and comprehensive cleaning process that goes
                    beyond regular cleaning routines. 
                    </p>

                    <p>Our deep cleaning services are useful to maintain a healthy and safe environment, especially in places that haven't been cleaned thoroughly for a while or where a higher level of cleanliness is required. 
                  </p>

                    <p>Here's a comprehensive description of our Deep-Cleaning services:</p>

                    <section className="parapoints">
                              <li className="pointitems"><h4>Dusting & Wiping Surfaces</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning surfaces, furniture, and objects to remove dust and dirt buildup.</span></li>
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Scrubbing & Disinfecting</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Thoroughly cleaning and disinfecting high-touch surfaces like doorknobs, light switches, remote controls, and countertops.</span></li>

                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Floor Care</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning and sanitizing floors, including vacuuming, mopping, and possibly waxing or polishing.</span></li>

                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Appliance Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning and sanitizing appliances such as ovens, microwaves, refrigerators, and washing machines.</span></li>

                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Bathroom Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Deep cleaning and disinfecting bathroom fixtures, tiles, showerheads, and toilets.</span></li>

                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Window Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning windows, frames, and blinds.</span></li>

                                    </ul>
                              </li>

                              <li className="pointitems"><h4>Decluttering & Organizing</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Organizing and tidying up spaces by removing clutter.</span></li>

                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Hard-to-reach Areas</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning areas that are often overlooked or hard to access, like behind furniture or inside cabinets.</span></li>

                                    </ul>
                              </li>
            
                  </section>
                    <a href="tel:929-261-2976" className='singlebookbtn'> Book My Deep-Cleaning </a>
                </section>
            </section>
           
            <section className="servicepoints">
              <h4 className="cleaningquest">Why Choose Our Deep-Cleaning Service?</h4>
              <section className="clusteredlist">
                     
              <li id="numitem"> 
                              <h4>Time Saving </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Deep cleaning takes extra time and effort but hiring our profesional team can save
time to focus on other important aspects of your life.</p>
                              </li>
                        </li>


                        <li id="numitem"> 
                              <h4>Maintenance </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Deep cleaning can help maintain the condition of certain areas and items that are not often cleaned,
preventing the buildup of dirt, dust, and grime.</p>
                              </li>
                        </li>

                        <li id="numitem"> 
                              <h4>Professional Quality </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Professional cleaners have the expertise and tools to achieve a higher
level of cleanliness than most individuals can achieve on their own.</p>
                              </li>
                        </li>
                        <li id="numitem"> 
                              <h4>Stress Reduction </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Having a clean and organized living space can reduce stress and contribute
to a better quality of life.</p>
                              </li>
                        </li>
               </section>
            </section> 
            <a className="singlebook" href="tel:929-261-2976">Request Quote</a>
            <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
      </div>
        
    );
  }

export default Deep