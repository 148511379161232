import React from 'react';
import '../assets/stylesheets/airbnb.css';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import location from '../assets/icons/location.png';
import { Link } from "react-router-dom";
const  MM = () => {
    return (
      <div id="airbnbcontainer">

            <section id="airbnbanner">

            

            </section>

            <section className="opensection">
                <section className="opencontent">
                            <h4 className="sectiontitle"> AirBnB Cleaning </h4>
                            <p>We know that choosing a reliable Airbnb Cleaning Service can be very 
                              overwhelming. There are so many details to take care of on every turnover. 
                              Let us be your partner, we will take care of all these tiring and 
                              time-consuming tasks so you can focus on being an excellent host. </p>

                              <p>Airbnb cleaning services are specialized cleaning solutions designed to help hosts manage the cleanliness and 
                                   upkeep of their properties. This ensures that guests have the best experience during their stay which can lead to positive reviews, 
                                   repeat bookings, and higher ratings. </p>
                                   <p>Here's a comprehensive description of our Airbnb Cleaning services:</p>

                        <section className="parapoints">
                              <li className="pointitems"><h4>General Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Dusting all surfaces, including furniture, countertops, and shelves.</span></li>
                                          <li className="subpoint"><span>Sweeping, mopping, or vacuuming all floors.</span></li>
                                          <li className="subpoint"><span>Cleaning and sanitizing high-touch surfaces such as doorknobs, light switches, and remote controls.</span></li>
                                          <li className="subpoint"><span>Wiping down mirrors and glass surfaces.</span></li>
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Bathroom Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Scrubbing and disinfecting toilets, sinks, showers, and bathtubs.</span></li>
                                          <li className="subpoint"><span>Cleaning and shining bathroom fixtures and mirrors.</span></li>
                                          <li className="subpoint"><span>Replacing used towels with fresh ones.</span></li>
                                          <li className="subpoint"><span>Restocking toilet paper, hand soap, and other essential toiletries.</span></li>
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Kitchen Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Cleaning kitchen appliances, including stovetop, oven, microwave, refrigerator, and dishwasher.</span></li>
                                          <li className="subpoint"><span>Wiping down countertops and backsplashes.</span></li>
                                          <li className="subpoint"><span>Washing dishes and utensils, or ensuring the dishwasher is emptied and ready for use.</span></li>
                                          <li className="subpoint"><span>Checking and restocking kitchen essentials such as coffee, tea, and cooking basics.</span></li>
                                    </ul>
                              </li>

                              <li className="pointitems"><h4>Bedroom Cleaning</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Changing bed linens and making the beds.</span></li>
                                          <li className="subpoint"><span>Dusting and cleaning all bedroom furniture.</span></li>
                                          <li className="subpoint"><span>Vacuuming or cleaning under the beds and furniture.</span></li>
                                    </ul>
                              </li>

                              <li className="pointitems"><h4>Living Room and Common Areas</h4> 
                                    <ul>
            
                                          <li className="subpoint"><span>Vacuuming or cleaning upholstery on couches and chairs.</span></li>
                                          <li className="subpoint"><span>Dusting and wiping down surfaces like coffee tables and entertainment centers.</span></li>
                                          <li className="subpoint"><span>Ensuring the space is tidy and clutter-free.</span></li>
                                    </ul>
                              </li>

                              <li className="pointitems"><h4>Laundry</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Washing and changing all used towels, bed linens, and any other linens in the property.</span></li>
                                          <li className="subpoint"><span>Folding and organizing clean linens for future use.</span></li>
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Trash Removal</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Emptying trash bins in all rooms and common areas.</span></li>
                                          <li className="subpoint"><span>Replacing trash can liners.</span></li>
            
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Exterior Areas</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Sweeping and cleaning exterior entryways and porches.</span></li>
                                          <li className="subpoint"><span>Ensuring outdoor spaces are presentable and free from debris.</span></li>
                                    </ul>
                              </li>
                              <li className="pointitems"><h4>Special Tasks</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Identifying and addressing any specific guest requests or cleaning requirements.</span></li>
                                          <li className="subpoint"><span>Reporting any damages or maintenance issues to the property owner or manager.</span></li>
                                          <li className="subpoint"><span>Restocking amenities like toiletries, cleaning supplies, and kitchen essentials.</span></li>
                                    </ul>
                              </li>

                              <li className="pointitems"><h4>Quality Check</h4> 
                                    <ul>
                                          <li className="subpoint"><span>Conducting a thorough walkthrough to ensure all cleaning tasks have been completed to a high standard.</span></li>
                                          <li className="subpoint"><span>Making sure the property is well-stocked and prepared for the next guest.</span></li>
                                    </ul>
                              </li>
                        </section>
                          <a href="tel:929-261-2976" className='singlebookbtn'> Book My Airbnb Cleaning </a>

              
                </section>
            </section>
            <section className="servicepoints">
            <h4 className="cleaningquest">Why Choose Our AiBnB Cleaning Service?</h4>
            <section className="clusteredlist">
                     
                     <li id="numitem"> 
                                     <h4>Time Saving </h4>
                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Cleaning your Airbnb can take extra time and effort but hiring our professional team can save you
       time to focus on other important aspects of your business.</p>
                                     </li>

                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Quick turnover time.</p>
                                     </li>
                               </li>
       
       
                               <li id="numitem"> 
                                     <h4>Maintenance </h4>
                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Maintaining a clean and tidy Airbnb ensures that your guests have a positive experience every stay.</p>
                                     </li>
                               </li>
       
                               <li id="numitem"> 
                                     <h4>Professional Quality </h4>
                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Our Professional cleaners have the expertise and tools to achieve a higher
       level of cleanliness than most individuals can achieve on their own.</p>
                                     </li>
                               </li>
                      </section>
           </section>
            <a className="singlebook" href="tel:929-261-2976">Request Quote</a>

            <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
       
      
      </div>
        
    );
  }

export default MM