import React from 'react';
import '../assets/stylesheets/office.css';
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import { Link } from "react-router-dom";

const  Apartment = () => {
    return (
      <div id="officecontainer">

            <section id="officebanner">

                  

            </section>

            <section className="opensection">

              <section className="opencontent">
                <h4 className="sectiontitle"> Office Cleaning </h4>
                <p>We provide office cleaning to professional companies and individuals that needs cleaning and maintenance services for 
                    their commercial and office spaces.  </p>

                    <p>These services are designed to ensure that the workplace remains clean, organized, 
                    and conducive to a productive and healthy work environment. Office cleaning services can vary in scope 
                    and frequency, ranging from daily maintenance to more specialized deep cleaning tasks.</p>

               <p> We know that when it comes to office cleaning, NYC businesses are conscious about 
               green energy and eco-friendly practices. We share this passion and ensure that we fulfill 
               our clients needs and values.</p>

               <p>Here's a comprehensive description of our Office Cleaning services:</p>

               <section className="parapoints">
                    <li className="pointitems"><h4>Restroom Sanitation</h4> 
                         <ul>
                              <li className="subpoint"><span>Cleaning and disinfecting restrooms to prevent the spread of germs and ensure a hygienic space for employees and guests.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Kitchen & Breakroom Cleaning</h4> 
                         <ul>
                              <li className="subpoint"><span>Cleaning kitchens and breakrooms to ensure food safety and maintain a pleasant atmosphere.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Waste Management</h4> 
                         <ul>
                              <li className="subpoint"><span>Emptying trash bins, recycling, and disposing of waste properly are essential tasks included in office cleaning services. This helps prevent odors, pests, and clutter from building up.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Window Cleaning</h4> 
                         <ul>
                              <li className="subpoint"><span>Cleaning windows and glass surfaces to maintain a clear view and improve natural lighting.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Dusting & Wiping Surfaces</h4> 
                         <ul>
                              <li className="subpoint"><span>Dusting all surfaces, including desks, shelves, countertops, and wiping down surfaces to remove dust, fingerprints, and smudges.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Floor Care</h4> 
                         <ul>
                              <li className="subpoint"><span>Cleaning and maintaining different types of flooring, including vacuuming carpets, mopping hard floors, and waxing or buffing when necessary.</span></li>
                         </ul>
                    </li>
                    <li className="pointitems"><h4>Emergency Cleaning</h4> 
                         <ul>
                              <li className="subpoint"><span>Providing cleaning services in the event of accidents, spills, or other unexpected messes.</span></li>
                         </ul>
                    </li>
               </section>
               <a href="tel:929-261-2976" className='singlebookbtn'> Book My Office Cleaning </a>
              </section>
          </section>

            

           <section className="servicepoints">
              <h4 className="cleaningquest">Why Choose Our Office-Cleaning Service?</h4>

              <section className="clusteredlist">
      
                    <li id="numitem"> 
                         <h4>Time-saving </h4>
                         <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                              <p>Save time and effort for employees who would otherwise have to perform cleaning tasks, allowing them to focus on their primary responsibilities.</p>
                         </li>

                    </li>
                    <li id="numitem"> 
                         <h4>Health and Safety </h4>
                         <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                              <p>Maintaining a clean office environment contributes to the health and safety of employees. Regular cleaning helps reduce the spread of illnesses, allergens, and contaminants that can affect employees well-being.</p>
                         </li>

                    </li>
                    <li id="numitem"> 
                         <h4>Professionalism </h4>
                         <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                              <p>Hiring a professional office cleaning service ensures that the cleaning tasks are carried out efficiently and effectively. Our trained and experienced cleaners use appropriate cleaning products and techniques to deliver consistent results.</p>
                         </li>

                    </li>
                    <li id="numitem"> 
                         <h4>Convenience </h4>
                         <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                              <p>Outsourcing office cleaning to our professional services allows your business to focus on its core activities without having to worry about cleaning tasks. This can lead to increased productivity and employee satisfaction.</p>
                         </li>

                    </li>
                    <li id="numitem"> 
                         <h4>Scheduled Maintenance </h4>
                         <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                              <p>Setting up regular cleaning schedules based on your office needs, whether it's daily, weekly, or monthly.</p>
                         </li>

                    </li>
     
               </section>
               
               
           </section>

          
           <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
           <a className="singlebook" href="tel929-261-2976">Request Quote</a>
       
      
      </div>
        
    );
  }

export default Apartment