import React from 'react';
import '../assets/stylesheets/about.css'
import { Link } from "react-router-dom";
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
const  About = () => {
    return (
      <div id="aboutcontainer">

        <section id="aboutbanner">

            

        </section>
            
        <section className="opensection">

                <section className="opencontent">
                      <h3  className="sectiontitle" > About Us </h3>
                      <p>Hariel’s Cleaning Co. is one of the leading providers of 
                        residential cleaning. Founded in 2019 and based in Queens, NY. 
                        Our services extend to Manhattan, Brooklyn and Queens. 
                      </p>
                      <p>
                      We are a dedicated team of hard working people whose priority 
                      is to make your life easier. We want to save you time so you can create
                       memories with your loved ones in the city or even simply enjoying the
                        comfort of your own home. NYC has a habit of keeping its citizens 
                        busy enough; let us help you cross things off of your to-do list. 
                        We do our best to provide a professional, detail-oriented, superior cleaning service.
                      </p>
                      <p>
                      <em>Let cleaning be your affordable luxury</em>, we are ready and equipped to accommodate your busy 
                      New York schedule and last-minute requests.
                      </p>
                      <h3 id="teamission" className="sectiontitle" > Our Team </h3>
                      <p>We aim at fostering a healthy work environment for our employees. 
                        We support each other as a TEAM. We are a team of hard working people 
                        from all walks of life, all passionate about creating a cleaner, safer 
                        environment for humanity. Our first priority is to provide exceptional 
                        cleaning service at our client's convenience. Our team is about creating 
                        the ultimate experience for our clients. Therefore, you can rest assured 
                        that all members of our team are fully trained, screened and background-checked 
                        prior to hiring. We are ready to serve you! 
                        </p>
                      <h3 id="teamission" className="sectiontitle" > Our Mission </h3>
                      <p>Our mission is to “Create a cleaner, safer environment for the wellbeing of 
                        humanity”. We believe in providing our clients with the highest quality home 
                        cleaning service at an affordable price using eco-friendly products. 
                        We are not satisfied until you are! We take the time to build 
                        meaningful relationships with our clients so we can better serve you!</p>
                </section>

        </section>

        <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
      </div>
        
    );
  }

export default About