
import React from 'react';
import './App.css';
import Header from './components/Header';
import Home from './views/Home';
import Contact from './views/Contact';
import About from './views/About';
import Services from './views/Services';
import Airbnb from './views/Airbnb-Cleaning';
import Apartment from './views/Apartment-Cleaning';
import Deep from './views/Deep-Cleaning';
import Office from './views/Office-Cleaning';
import MM from './views/MM-Cleaning';


import { Routes, Route } from 'react-router-dom';
//import callicon from './assets/icons/callicon.png';

const  App = () => {
  return (
    <>
      <div className="App">
         <Header />
      </div>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/airbnb-cleaning" element={<Airbnb />} />
          <Route path="/apartment-cleaning" element={<Apartment />} />
          <Route path="/deep-cleaning" element={<Deep />} />
          <Route path="/office-cleaning" element={<Office />} />
          <Route path="/move-in-move-out-cleaning" element={<MM />} />
       </Routes>
    </>
   
  );
}

export default App;
