import React from 'react';
import '../assets/stylesheets/services.css'

const  Services = () => {
    return (
      <>

        <section id="servicebanner">

          <h3>Services</h3>

        </section>
      
      </>
        
    );
  }

export default Services