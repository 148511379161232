import React from 'react';

import '../assets/stylesheets/home.css';

import awardicon from '../assets/icons/award-symbol.png';
import apartmenticon from '../assets/icons/apartment (1).png';
import officeicon from '../assets/icons/office (1).png';
import airbnbicon from '../assets/icons/airbnb (1).png';
import deepcleanicon from '../assets/icons/housekeeping.png';
import mmicon from '../assets/icons/mmicon.png';
import ratingstars from '../assets/icons/ratingstarsconverted.png';
import googlereviews from '../assets/images/googlereviews.png';
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';

import { Link } from "react-router-dom";


import swiffer from '../assets/images/swiffer.png';
import zep from '../assets/images/zep.png';
import naturepure from '../assets/images/naturepure.png';
import seventhgen from '../assets/images/seventhgen.png';
import meyers from '../assets/images/meyers.png';
import mrclean from '../assets/images/mrclean.png';
import ecover from '../assets/images/ecover.png';
import method from '../assets/images/method.png';

const  Home = () => {



  
  window.addEventListener('load', ()=>{
       let reviewboxstatus = false;
       const seemore = setInterval(()=>{
          if (document.contains(document.querySelector("span#seemorereviews"))){
            const smore = document.querySelector("span#seemorereviews");
            const smoreboxes =  document.querySelector("section.reviewboxes"); 
            const showmore = document.querySelector("span#showmore"); 
            clearInterval(seemore);
            smore.addEventListener('click', ()=>{
            
              
          
                if (reviewboxstatus === false){
                    smoreboxes.style.display = 'grid';
                    reviewboxstatus = true;
                    showmore.innerHTML="Show Less Reviews";
                }
                else{
                  smoreboxes.style.display = 'none';
                  reviewboxstatus= false;
                  showmore.innerHTML="Show More Reviews";
                }
            })
          }
       },0);

        
      
  });

    return (
        <>
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active " data-interval="1500">
                          <section className="banner" id="banner1">
                              <section id="actionbuttongrp">
                                <h2>Make Cleaning Your Affordable Luxury</h2>
                                
                                  <button> <a href="tel:929-261-2976">BOOK APPOINTMENT</a></button>
                              </section>
                          </section>
                      </div>
                      <div className="carousel-item" data-interval="1500">
                        <section className="banner" id="banner2">
                            <section id="actionbuttongrp">
                                <button> <a href="tel:929-261-2976">BOOK APPOINTMENT</a></button>
                            </section>
                        </section>
                      </div>
                      <div className="carousel-item" data-interval="1500">
                        <section className="banner" id="banner3">
                            <section id="actionbuttongrp">
                                <button> <a href="tel:929-261-2976">BOOK APPOINTMENT</a></button>
                            </section>
                        </section>
                      </div>

                      <div className="carousel-item" data-interval="1500">
                        <section className="banner" id="banner4">
                            <section id="actionbuttongrp">
                                <button> <a href="tel:929-261-2976">BOOK APPOINTMENT</a></button>
                            </section>
                        </section>
                      </div>

                      <div className="carousel-item" data-interval="1500">
                        <section className="banner" id="banner5">
                            <section id="actionbuttongrp">
                                <button> <a href="tel:929-261-2976">BOOK APPOINTMENT</a></button>
                            </section>
                        </section>
                      </div>

                      
                    </div>
              </div>
          <main>
             
             <section className="opensection">

                       <section className="opencontent">
                            <h3 className="sectiontitle">Your One-Stop For Reliable Professional Cleaning</h3>
                              <p>We know the non-stop lifestyle of a typical New Yorker can be difficult, and does 
                                not leave much time for chores. That is why we offer flexible hours and competitive rates 
                                just to help you maintain the lifestyle you desire and deserve. Whether it be an apartment, office, 
                                Airbnb, etc. we've got you covered. Our cleaners are professional and equipped to tackle all your 
                                cleaning needs at your convenience.<span> A clean apartment is a healthy one.</span>
                              </p>
                       </section>
             </section>

            <section className="mainsection">
                
                  <section className='content'>
                        <img className="serviceicon" alt="apartment cleaning" src={apartmenticon}/>
                        <h4 className="sectiontitle">Apartment Cleaning</h4>
                        <p>It can be quite difficult to relax in an apartment in need of cleaning, and it is 
                  often hard to find time to give your living space a basic clean... <Link to="/apartment-cleaning">Learn More {'\u2192'}</Link> </p>
                  </section>


                  <section className="content">
                        <img className="serviceicon" alt="office cleaning" src={officeicon}/>
                        <h4 className="sectiontitle">Office Cleaning</h4>
                        <p>We provide a sought after solution for companies that prioritize a clean and safe
                          workspace ...<Link to="/move-in-move-out-cleaning">Learn More {'\u2192'}</Link> </p>
                  </section>

                  <section className="content">
                    <img className="serviceicon" alt="airbnb cleaning" src={airbnbicon}/>
                    <h4 className="sectiontitle">AirBnB Cleaning</h4>
                    <p>Finding a reliable Airbnb Cleaning Service can be very overwhelming. 
                              There are so many details to take care of on every turnover on a 5-stars 
                              property. <Link to="/airbnb-cleaning">Learn More {'\u2192'}</Link></p>
                  </section>
            </section>
      
            
            <section id="lastwo" className="mainsection">
             
                  <section className="content">
                      <img className="serviceicon" alt="deep cleaning" src={deepcleanicon}/>
                      <h4 className="sectiontitle">Deep Cleaning</h4>
                      <p>We provide a deep and thorough clean of your apartment that needs a little extra help. 
                        Services may include refrigerator cleaning, oven cleaning, baseboard cleaning and interior 
                        window washing...<Link to="/deep-cleaning">Learn More {'\u2192'}</Link></p>
                  </section>


                  <section className="content">
                      <img className="serviceicon" alt="move in move out cleaning" src={mmicon}/>
                      <h4 className="sectiontitle">Move-in/Move-out Cleaning</h4>
                      <p>If you’re one of the millions of New Yorkers who every year are moving out of an old apartment, condo, or home and into a new one, 
                        cleaning is very often at the bottom of the priority list with so many other more important tasks to do....<Link to="/move-in-move-out-cleaning">Learn More {'\u2192'}</Link></p>
                  </section>

                 
            </section>



            <section className="clients">

                <section className="picture">
                  <h1> Real Clients, Real Results</h1>

                </section>

                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">

                    <div className="carousel-item active">
                              <div className="card">
                                  <div className="card-body">
                                        <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4">
                                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                                            <i className="fa fa-quote-left text-white"></i>
                                          </div>
                                          <div className="ratingstar">
                                              <img src={ratingstars} alt="ratings" />
                                          </div>
                                          <p>
                                          Hariel has been cleaning my apartment for about two years. She is the best! Thorough, careful, professional and just does a great job. I trust Hariel completely. Highly recommended!
                                          </p>
                                          <footer className="blockquote-footer pt-4 mt-4 border-top">
                                            
                                            <cite title="Source Title">Libbie Walker</cite>
                                          </footer>
                                          
                                        </blockquote>
          
                                    </div>
                                    
                              </div>
                          </div>

                                               
                          <div className="carousel-item">
                              <div className="card">
                                  <div className="card-body">
                                        <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4">
                                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                                            <i className="fa fa-quote-left text-white"></i>
                                          </div>
                                          <div className="ratingstar">
                                              <img src={ratingstars} alt="ratings" />
                                          </div>
                                          <p>
                                            "Hariel’s Cleaning Company is THE best. Always on time, efficient, friendly, and extremely thorough cleaning job. I highly recommend Hariel for all your cleaning needs!"
                                          </p>
                                          <footer className="blockquote-footer pt-4 mt-4 border-top">
                                            
                                            <cite title="Source Title">Jenna Cook</cite>
                                          </footer>
                                          
                                        </blockquote>
          
                                    </div>
                                    
                              </div>
                          </div> 

                          <div className="carousel-item">
                              <div className="card">
                                  <div className="card-body">
                                        <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4">
                                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                                            <i className="fa fa-quote-left text-white"></i>
                                          </div>
                                          <div className="ratingstar">
                                              <img src={ratingstars} alt="ratings" />
                                          </div>
                                          <p>
                                            "I use Hariel’s cleaning service on a monthly basis. I am extremely picky about cleaning, because I’m a bit of a germ-phobe, 
                                            and also because I expect a detail oriented cleaning where beyond surfaces, the cracks, corners, wall marks, under radiators, 
                                            baseboards, etc. are all things that matter greatly to me! After going through MANY different services I was finally so satisfied
                                             and so happy to encounter Hariel’s. I HIGHLY recommend!"
                                          </p>
                                          <footer className="blockquote-footer pt-4 mt-4 border-top">
                                            
                                            <cite title="Source Title">Alyson  Loney</cite>
                                          </footer>
                                          
                                        </blockquote>
          
                                    </div>
                                    
                              </div>
                          </div> 


                    <div className="carousel-item ">
                              <div className="card">
                                  <div className="card-body">
                                        <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4">
                                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                                            <i className="fa fa-quote-left text-white"></i>
                                          </div>
                                          <div className="ratingstar">
                                              <img src={ratingstars} alt="ratings" />
                                          </div>
                                          <p> Hariel has been with us for several years now and when we moved boroughs we asked her to please keep coming to our new place because we enjoy her so much.  She always does a fantastic job and is such a sweet person.  Would totally recommend to any friends or family. </p>
                                         
                                          <footer className="blockquote-footer pt-4 mt-4 border-top">
                                            
                                            <cite title="Source Title">Ryan Kenney</cite>
                                          </footer>
                                          
                                        </blockquote>
          
                                    </div>
                                    
                              </div>
                          </div>


                          <div className="carousel-item ">
                              <div className="card">
                                  <div className="card-body">
                                        <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4">
                                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                                            <i className="fa fa-quote-left text-white"></i>
                                          </div>
                                          <div className="ratingstar">
                                              <img src={ratingstars} alt="ratings" />
                                          </div>
                                          <p>
                                          Hariel has regularly cleaned our apartment now for over a year, and she is simply the best. Always on time, professional, and thorough. She is also easy to communicate with, and responsive and flexible when life gets in the way and schedules change.
                                          </p>
                                          <footer className="blockquote-footer pt-4 mt-4 border-top">
                                            
                                            <cite title="Source Title">Matthew Kinsey</cite>
                                          </footer>
                                          
                                        </blockquote>
          
                                    </div>
                                    
                              </div>
                          </div>   
                         

              
                    </div>
                   
            <section className="trustpilot">
                        <img id="googlebadge" src={googlereviews} alt="google reviews" />
                        <a href="https://g.page/r/CUPhPQOC5CehEB0/review" className='writereview'> Write A Review </a>
                        {/*<a href="https://www.yelp.com/writeareview/biz/sQ1iiotXnGv7-q9B1cAaFg?return_url=%2Fbiz%2FsQ1iiotXnGv7-q9B1cAaFg&review_origin=biz-details-war-button" className='writereview'> Write A Review </a>*/}
            </section>
                  </div>
                 
              
            </section>
            <section className="morereviews">
                        <span id="seemorereviews"> <span id="showmore">Show More Reviews</span> <img alt="down chevron" src={require('../assets/icons/down-chevron.png')} />  </span>
                        <section className="reviewboxes">

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_neeco.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Neeco Palmer</h4>
                        
                                          <p>Hariel, is professional, courteous, and attentive which is hard to come by these days. Shashanie is a thorough cleaner and is friendly and accomodating. I'm thrilled with this group! If I could, I'd hire them to do my gardening, contracting, plumbing, and electric too!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section>

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_barry.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Barry Ang</h4>
                        
                                          <p>My partner and I moved into a new apartment in Williamsburg and the place was extremely dirty, especially the floors. The apartment hadn’t been deep cleaned in over two years. We hired Hariel to deep clean the entire 800sq foot apartment, including the kitchen cabinets and closets. Hariel’s work was meticulous and she left the apartment pristine! We did a test to wipe the floors with a white rag, and after wiping, the white rag did not have any marks whatsoever. We have set up a recurring cleaning because we cannot live without Hariel’s cleaning! Thank you Hariel!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section>

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_jan.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Jan C</h4>
                        
                                          <p>Hariel is prompt, thorough, and super friendly. The best cleaning service I have used in NYC!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section>









                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_shayla.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Shayla Dhingra</h4>
                        
                                          <p>Hariel has been cleaning my apartment for a few months now and every time she has done a stellar job. She immediately picks up on where things are without me even having to say and is very professional in providing spotless, efficient cleaning. Not only has she cleaned up and made my house feel refreshed, she has also helped me organize my drawers which was really supportive. Also a huge bonus, she gets along really well with my dog who can be a bit picky with people. I recommend her to all my friends and trust she will always provide great service every time she comes by!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section>
                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_rachel.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Rachel Enyi</h4>
                        
                                          <p>Excellent cleaning service. Didn’t miss a spot. Will surely call them back.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section>
                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_shalini.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Shalini Acharya</h4>
                        
                                          <p>We recently started using Hariel’s cleaning co and are very happy with the results. Extremely efficient! We hope to continue with her services long term.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 
                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_simone.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Simone Johnson</h4>
                        
                                          <p>Hariel was recommended by a friend of myself and my husband, we needed someone to clean our home. However, we were very scared of hiring just anyone because we do lots of traveling and that person would be in our home when we are away, but we were fortunate to have her. She is reliable, trustworthy and does a terrific job. We're never disappointed and therefore we recommend her to everyone <span>😊</span> </p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                 </section>
                        </section> 
                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_runnan.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Runnan Tina Zhong</h4>
                        
                                          <p>We have been really happy to have encountered Hariel. We have been with her for a couple of years and we would recommend her in a heartbeat. She is a professional and really delivers high quality cleaning every time!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_cheresa.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Cheresa Jerrick</h4>
                        
                                          <p>Very Professional and outstanding service left everything spotless I highly recommend them</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_scotty.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Scotty Leach</h4>
                        
                                          <p>Hariel is the best cleaner i’ve ever had.  She is professional, efficient, and trustworthy!  She’s never been late and she always finishes the cleaning within the allotted time.  We’ve hired her for the last year or so and by the second cleaning she knew the in’s and outs of our apartment.  She also has great energy and doesn’t feel like a stranger is cleaning our space.  We’ve hired her to clean while we aren’t home a lot and she does just as good a job.  thank you for your services hariel, i recommend her to everyone!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 


                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_neil.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Neil V. Shah</h4>
                        
                                          <p>Hariel consistently does an excellent job in our home, is courteous and thorough, and really pays attention to details when cleaning. Would highly recommend and have referred her to friends!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_elton.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Elton Medina</h4>
                        
                                          <p>1st time ever using a cleaning service and Hariel did not disappoint!! Great customer service and exceptional results will definitely book again!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                        </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profle_photo_ryan.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Ryan Kenney</h4>
                        
                                          <p>Hariel has been with us for several years now and when we moved boroughs we asked her to please keep coming to our new place because we enjoy her so much.  She always does a fantastic job and is such a sweet person.  Would totally recommend to any friends or family.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                          </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_libbie.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Libbie Walker</h4>
                        
                                          <p>Hariel has been cleaning my apartment for about two years. She is the best! Thorough, careful, professional and just does a great job. I trust Hariel completely. Highly recommended!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                          </section> 

                        <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_chelsey.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Chelsey</h4>
                        
                                          <p>Hariel has been cleaning for us for a few years. She is reliable, thorough and professional. Highly recommend!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                          </section> 
                              <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_alyson.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Alyson Loney</h4>
                        
                                          <p>I use Hariel’s cleaning service on a monthly basis. I am extremely picky about cleaning, because I’m a bit of a germ-phobe, 
                                            and also because I expect a detail oriented cleaning where beyond surfaces, the cracks, corners, wall marks, under radiators, 
                                            baseboards, etc. are all things that matter greatly to me! After going through MANY different services I was finally so satisfied
                                             and so happy to encounter Hariel’s. I HIGHLY recommend!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section> 
                              <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_matt.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Matthew Kinsey</h4>
                        
                                          <p>Hariel has regularly cleaned our apartment now for over a year, and she is simply the best. Always on time, professional, and thorough. She is also easy to communicate with, and responsive and flexible when life gets in the way and schedules change.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section> 
                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/mia.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Mia Zenda</h4>
                        
                                          <p>Hariel is so great! I’ve hired her a few times, and she is always professional and thorough. She goes above and beyond, and makes our place feel brand new.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section> 

                              <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_denardo.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Denardo Demetrius</h4>
                        
                                          <p>With a busy lifestyle like mine I definitely needed this cleaning service. Hariel’s Cleaning will definitely dot all your i’s and cross all your t’s especially if you’re picky like me. 10/10 service !</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>                          

                              <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_leigh.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Leigh Demetrius</h4>
                        
                                          <p>My husband and I are parents to a 7 month old, so trust me when I say we can’t find the time or strength to clean our apartment. Hariel’s Cleaning Co has been a life savior! Our apartment is always spotless so that’s one less thing to worry about. Thank you Hariel!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>
                          
                              <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_guy.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Guy Polani</h4>
                        
                                          <p>Hariel is great. Extremely reliable and does a great job every time!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>


                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_jenna.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Jenna Cook</h4>
                        
                                          <p>Hariel’s Cleaning Company is THE best. Always on time, efficient, friendly, and extremely thorough cleaning job. I highly recommend 
                                            Hariel for all your cleaning needs!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>
                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_eva.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Eva Francois</h4>
                        
                                          <p>Great service, I would have been using it on a weekly basis. Hariel is always on time and delievers excellent service.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>
                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_abby.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Abby Haywood</h4>
                        
                                          <p>Hariel has cleaned for me for several years and always does an outstanding job! Such a pleasure to 
                                            return to a house that is fresh and clean!</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>
                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_shemar.png')}alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                        <h4>Shemar Reid</h4>
                                        
                                        <p>My cleaner arrived on time and was very professional. I will definitely use this company again and recommend to my friends.</p>
                                       <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>

                                <section className="reviewbox">
                                      <img className="profilephoto" src={require('../assets/icons/profile_photo_shawn.png')} alt="profile"/>
                                      <section className="reviewboxcontent">
                                      <img src={ratingstars} alt="ratings" />
                                          <h4>Shawn Campbell</h4>
                        
                                          <p>My place was left smelling so fresh and feeling so clean.</p>
                                         <span>Posted on <img src={require('../assets/icons/google.png')} alt="ratings" /></span>                                      </section>
                                </section>

                               
                                
                        </section>
            </section>  
            <section className="awards">
                  <section className='awardcontent'>
                        <img  id="awardicons" className="serviceicon" alt="apartment cleaning" src={awardicon}/>
                        <h4 className="sectiontitle">Affordable</h4>
                  </section>

                  <section className='awardcontent'>
                        <img  id="awardicons"  className="serviceicon" alt="apartment cleaning" src={awardicon}/>
                        <h4 className="sectiontitle">Luxury</h4>
                       
                  </section>

                  <section className='awardcontent'>
                        <img  id="awardicons"  className="serviceicon" alt="apartment cleaning" src={awardicon}/>
                        <h4 className="sectiontitle">Convenient</h4>
                       
                  </section>

                  <section className='awardcontent'>
                        <img  id="awardicons"  className="serviceicon" alt="apartment cleaning" src={awardicon}/>
                        <h4 className="sectiontitle">Reliable</h4>
                       
                  </section>

                  <section id="awardicons" className='awardcontent'>
                        <img  id="awardicons"  className="serviceicon" alt="apartment cleaning" src={awardicon}/>
                        <h4 className="sectiontitle">Experience</h4>
                       
                  </section>
            </section>

            <section className="cleaningproducts">

                    <h1>We Use The Best</h1>

                    <div className="cleaningitems">
                          <img src={swiffer} alt="swiffer logo" />
                          <img src={zep} alt="zep logo" />
                          <img src={seventhgen} alt="seventh generation logo" />
                          <img src={naturepure} alt="nature pure logo" />
                          <img src={meyers} alt="mrs meyers logo" />
                          <img src={mrclean} alt="mr clean logo" />
                          <img src={method} alt="method logo" />
                          <img src={ecover} alt="ecover logo" />
                    </div>
            </section>

            <section className="quickinfo">


                <div className="usefulinks">
                   <img id="starsingle" src={links} alt="links" />
                    <ul>
                        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
                        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
                        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
                        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out-Cleaning</Link></li>
                        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
                    </ul>
                </div>

                <div className="address">
                        <img id="starsingle" src={location} alt="location" />
                        <span>102-25 185th St</span>
                        <span>11423</span>
                        <span>Hollis</span>
                        <span>NY,11423</span>
                        <span>United States</span>
                </div>

                <div className="contactfooter">
                <img id="starsingle" src={contactmail} alt="contactmail" />
                <span>info@harielscleaningco.com</span>
                <span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
                </div>
            </section>
 
          </main>
        </>
    );
  }

export default Home