import React from 'react';
import '../assets/stylesheets/mm.css';
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import { Link } from "react-router-dom";

const  MM = () => {
    return (
      <div id="mmcontainer">

            <section id="mmbanner">

            

            </section>

            <section className="opensection">

              <section className="opencontent">
                <h3 className="sectiontitle"> Move-in/Move-out Cleaning </h3>
            <p>We know that when moving, cleaning can take a back seat. Why not let us take care 
               of your move-in or move-out cleaning so that you can enjoy peace of 
               mind knowing that your old space has been properly cleaned or ready to be moved into? </p>
            <p> The goal of this service is to ensure that the space is thoroughly 
               cleaned and ready for the new occupants or for the landlord's inspection.</p>
              <p>Here's a comprehensive description of our Move-in/Move-out Cleaning services:</p>
               <section className="parapoints">
                      <li className="pointitems"><h4>Kitchen Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Thoroughly cleaning appliances, cabinets, countertops, sinks, and other kitchen areas including stoves, refrigerators, and microwaves.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Bathroom Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Cleaning and disinfecting toilets, showers, tubs, sinks, and bathroom fixtures.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Dusting & Vacuuming</h4> 
                            <ul>
                                  <li className="subpoint"><span>Removing dust from all surfaces, including baseboards, shelves, and other nooks and crannies.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Window Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Cleaning interior windows and glass surfaces.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Appliance Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Cleaning and degreasing appliances, as well as removing any food residue.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Fixture Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Ensuring all light fixtures, switches, and outlets are clean.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Floor Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Sweeping, mopping, and vacuuming all floors, including under furniture.</span></li>
                            </ul>
                      </li>
                      <li className="pointitems"><h4>Wall Cleaning</h4> 
                            <ul>
                                  <li className="subpoint"><span>Removing marks, scuffs, and fingerprints from walls.</span></li>
                            </ul>
                      </li>
                </section>
              <a href="tel:929-261-2976" className='singlebookbtn'> Book My Move-in/Move-out Cleaning </a>
              </section>
           </section>

            <section className="servicepoints">
                <h4 className="cleaningquest">Why Choose Our Move-in/Move-out Service?</h4>
                <section className="clusteredlist">
                     
                     <li id="numitem"> 
                                     <h4>Time Saving </h4>
                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Save time and effort by hiring our profesional team to take care
                                                of your move-in/move-out cleaning needs so you have more
       time to focus on other important things.</p>
                                     </li>
                               </li>
       
       
       
                               <li id="numitem"> 
                                     <h4>Professional Quality </h4>
                                     <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                           <p>Professional cleaners have the expertise and tools to achieve a higher
       level of efficiency than most individuals can achieve on their own.</p>
                                     </li>
                               </li>
      
                      </section>

            </section>
            <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
            <a className="singlebook" href="tel929-261-2976">Request Quote</a>

       
      
      </div>
        
    );
  }

export default MM