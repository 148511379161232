import React, {useState} from 'react';
import { Link } from "react-router-dom";
import '../assets/stylesheets/header.css';

const  Header = () => {
    const [img, setImg] = useState(require('../assets/icons/menu (1).png'));
    const [servicedrop, setServiceDrop] = useState(require('../assets/icons/white_down-arrow.png'));
    let isopen = false;
    let islapopen =  false;
    let isdropopen = false;
  
    window.addEventListener('load', ()=>{
          const menuicon =  document.querySelector('img#menuicon');
          const services =  document.querySelector('li#services');
          const serviceitems = document.querySelector('div.servicedropitems');
          const menuitems =  document.querySelectorAll('ul#mobilemenu li a');
          const lapmenuitems =  document.querySelectorAll('ul#laptopdropdown li a');
          const desktopmenuitems =  document.querySelectorAll('nav ul li a');
          const servicelnk =  document.querySelector('a#servicelnk');

        

          lapmenuitems.forEach((lapitem)=>{
                lapitem.addEventListener("click",()=>{

                  document.querySelector("ul#laptopdropdown").style.display ="none";
                  islapopen = false;
                  servicelnk.classList.remove("activ");

                });
          })


          servicelnk.addEventListener("click", ()=>{
                if (islapopen){
                    document.querySelector("ul#laptopdropdown").style.display ="none";
                    islapopen = false;
                    servicelnk.classList.remove("activ");
                }
                else{

                    servicelnk.classList.add("activ");
                    document.querySelector("ul#laptopdropdown").style.display ="flex";
                    islapopen = true;

                }
          });

          menuicon.addEventListener('click', ()=>{
              
                if (isopen) {
                   isopen = false;
                   isdropopen = false;
                   setImg(require('../assets/icons/menu (1).png'));
                   document.querySelector('img#menuicon').style.width = "2em";
                   document.querySelector("ul#mobilemenu").style.display ='none';
        
                   serviceitems.style.display = 'none';
                }
                else{
                  isopen = true;
                  setImg(require('../assets/icons/close (1).png'))
                  document.querySelector('img#menuicon').style.width = "1.5em";
                  document.querySelector("ul#mobilemenu").style.display ='flex';
                 
                }
            }
          );

          menuitems.forEach(element => {
                element.addEventListener('click', ()=>{
                    isopen = false;
                    setImg(require('../assets/icons/menu (1).png'));
                    document.querySelector("ul#mobilemenu").style.display ='none';

                    menuitems.forEach(element=>{
                      element.style.textDecoration= "none";
                    });
                    element.style.textDecoration= "underline";
                })
          });

          desktopmenuitems.forEach(element => {
            element.addEventListener('click', ()=>{
               
                desktopmenuitems.forEach(element=>{
                  element.style.textDecoration= "none";
                });
                element.style.textDecoration= "underline";
            })
      });


      services.addEventListener('click', ()=>{
         
         if (isdropopen){
          setServiceDrop(require('../assets/icons/white_down-arrow.png'));
          document.querySelector('img#servicesdropdown').style.width ='1.2em';
            serviceitems.style.display='none';
            isdropopen  = false;
         }
         else{
          setServiceDrop(require('../assets/icons/white_up_arrow.png'));
           document.querySelector('img#servicesdropdown').style.width ='0.9em';
           serviceitems.style.display='block';
           isdropopen  = true;
         }
      })



    })
    return (
    <>
       <header>
            <nav>
              <img id="menuicon" alt="menu icon" src={img} />
              <Link id="logo" to='/'> <img id="mainlogo" src={require('../assets/icons/logo.jpg')} alt="" /> </Link>
              <ul>
                  <li><Link reloadDocument to='/'>Home</Link></li>
                  <li><Link id="servicelnk" >Services</Link></li>
                  <li><Link to='/about'>About</Link></li>
                  <li><Link  to='/contact'>Contact</Link></li>
              </ul>
            </nav>
       </header>
       <ul id="mobilemenu">
          <li> <img className="menuicon" src={require('../assets/icons/homewhite.png')} alt="home icon" /> <Link reloadDocument to='/'>Home</Link></li>
          <li id="services"><img className="menuicon" src={require('../assets/icons/serviceswhite.png')} alt="services icon" />Services <span id="servicesarrow"><img id="servicesdropdown" src={servicedrop} alt="dropdown icon for services" /></span></li>
           <div className="servicedropitems">
                <li><Link  to='/apartment-cleaning'>Apartment Cleaning</Link></li>
                <li><Link  to='/office-cleaning'>Office Cleaning</Link></li>
                <li><Link  to="/deep-cleaning">Deep Cleaning</Link></li>
                <li><Link  to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
                <li><Link  to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
           </div>
          <li><img className="menuicon" src={require('../assets/icons/about-us.png')} alt="about us icon" /><Link to='/about'>About</Link></li>
          <li><img className="menuicon" src={require('../assets/icons/contact-us.png')} alt="call icon" /><Link to='/contact'>Contact</Link></li>
          
      </ul>

      <ul id="laptopdropdown">

          <li><Link  to='/apartment-cleaning'>Apartment Cleaning</Link></li>
          <li><Link  to='/office-cleaning'>Office Cleaning</Link></li>
          <li><Link  to="/deep-cleaning">Deep Cleaning</Link></li>
          <li><Link  to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
          <li><Link  to="/airbnb-cleaning">AirBnB Cleaning</Link></li>

      </ul>
    </> 
    );
  }

export default Header