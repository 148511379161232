import React from 'react';
import '../assets/stylesheets/apartment.css';
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import { Link } from "react-router-dom";

const  Apartment = () => {
    return (
      <div id="apartmentcontainer">

            <section id="apartmentbanner">

            

            </section>
          <section className="opensection">

              <section className="opencontent">
                <h3 className="sectiontitle"> Apartment Cleaning </h3>
                <p>It can be quite difficult to relax in an apartment in need of cleaning, and it is 
                  often hard to find time to give your living space a basic clean. So why not let
                   our dedicated team transform 
                   your apartment at your convenience? </p>
                   <p>We provide professional apartment cleaning solutions for residential spaces, catering to the needs of
individuals and families who require assistance in maintaining a clean and organized living
environment. </p>
<p>Here's a comprehensive description of our Apartment Cleaning services: </p>

<section className="parapoints">
      <li className="pointitems"><h4>Dusting and Wiping Surfaces</h4> 
            <ul>
                  <li className="subpoint"><span>Dusting all surfaces, including furniture, shelves, countertops, and decorations.</span></li>
                  <li className="subpoint"><span>Wiping down surfaces to remove dust, fingerprints, and smudges.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Vacuuming & Sweeping</h4> 
            <ul>
                  <li className="subpoint"><span>Vacuuming carpets, rugs, and upholstered furniture to remove dirt and debris.</span></li>
                  <li className="subpoint"><span>Sweeping and/or mopping hard floors to remove dust and spills.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Kitchen Cleaning</h4> 
            <ul>
                  <li className="subpoint"><span>Cleaning and sanitizing kitchen countertops, sinks, and backsplashes.</span></li>
                  <li className="subpoint"><span>Wiping down appliances (microwave, oven, stovetop, refrigerator) both inside and outside.</span></li>
                  <li className="subpoint"><span>Cleaning the interior and exterior of kitchen cabinets.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Bathroom Cleaning</h4> 
            <ul>
                  <li className="subpoint"><span>Cleaning and sanitizing bathroom sinks, countertops, mirrors, and fixtures.</span></li>
                  <li className="subpoint"><span>Scrubbing and disinfecting the toilet, bathtub, shower, and tiles.</span></li>
                  <li className="subpoint"><span>Cleaning the bathroom floor and removing any soap scum or grime.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Dusting & Cleaning Furniture</h4> 
            <ul>
                  <li className="subpoint"><span>Dusting and wiping down furniture, including tables, chairs, and other surfaces.</span></li>
                  <li className="subpoint"><span>Polishing wood surfaces to maintain their appearance.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Trash Removal</h4> 
            <ul>
                  <li className="subpoint"><span>Emptying trash cans and replacing garbage bags.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Window & Mirror Cleaning</h4> 
            <ul>
                  <li className="subpoint"><span>Cleaning windows, glass doors, and mirrors to remove smudges and streaks.</span></li>
            </ul>
      </li>
      <li className="pointitems"><h4>Bedroom Cleaning</h4> 
            <ul>
                  <li className="subpoint"><span>Making beds and changing bed linens (if requested).</span></li>
                  <li className="subpoint"><span>Dusting and cleaning bedroom furniture and decorations.</span></li>
            </ul>
      </li>
</section>
                  <a href="tel:929-261-2976" className='singlebookbtn'> Book My Apartment Cleaning </a>
              </section>
          </section>
           
            <section className="servicepoints">
            <h3 className="cleaningquest">Why Choose Our Apartment-Cleaning Service?</h3>
                     <section className="clusteredlist">
                        <li id="numitem"> 
                              <h4>Time Saving </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Cleaning takes time and effort but with a regular cleaning service, you can save
time and focus on other important aspects of your life.</p>
                              </li>
                        </li>

                        <li id="numitem"> 
                              <h4>Consistency </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                        
<p>Schedule your apartment to be cleaned on a consistent basis whether weekly, bi-weekly or monthly, making it a more pleasant
living environment. </p>
                              </li>
                        </li>

                        <li id="numitem"> 
                              <h4>Maintenance </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Regular cleaning can help maintain the condition of your apartment,
preventing the buildup of dirt, dust, and grime.</p>
                              </li>
                        </li>

                        <li id="numitem"> 
                              <h4>Professional Quality </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Professional cleaners have the expertise and tools to achieve a higher
level of cleanliness than most individuals can achieve on their own.</p>
                              </li>
                        </li>
                        <li id="numitem"> 
                              <h4>Stress Reduction </h4>
                              <li  className=" clustered"> <img className="servicepoint" src={require('../assets/icons/checklisticon.png')} alt="call icon" />
                                    <p>Having a clean and organized living space can reduce stress and contribute
to a better quality of life.</p>
                              </li>
                        </li>

                    </section>
            </section>
           <a className="singlebook" href="tel:9292612976">Request Quote</a>

           <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:9292612976">+(929)-261-2976</a></span>
</div>
</section>
      
      </div>
        
    );
  }

export default Apartment