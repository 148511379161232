import React from 'react';
import '../assets/stylesheets/contact.css'
import location from '../assets/icons/location.png';
import links from '../assets/icons/links.png';
import contactmail from '../assets/icons/contact-mail.png';
import contactemail from '../assets/icons/email2.gif';
import contactphone from '../assets/icons/phone.gif';
import { Link } from "react-router-dom";

const  Contact = () => {
    return (
      <div id="contactparent">

        <section id="contactbanner">

          

        </section>

        <section className="contactcontainer">
               <h3 className="sectiontitle"> Customer Service </h3>
              <p>Our customer service team is here to help! We aim to reply within 24 hours.</p>
              <p>Get in touch with us via email or give us a call.</p>
    
              <section id="contactfirstrow" className="contactrow">
                      <img  src={contactphone} alt="phone icon" />
                      <h3><a href="tel:929-261-2976">+(929)-261-2976</a></h3>
              </section>

              <section className="contactrow">
                      <img  src={contactemail} alt="email icon" />
                      <h3>info@harielscleaningco.com</h3>
              </section>
             
        </section>
        
    
        
        <section className="quickinfo">


<div className="usefulinks">
   <img id="starsingle" src={links} alt="links" />
    <ul>
        <li><Link to='/apartment-cleaning'>Apartment Cleaning</Link></li>
        <li><Link to='/office-cleaning'>Office Cleaning</Link></li>
        <li><Link to="/deep-cleaning">Deep Cleaning</Link></li>
        <li><Link to="/move-in-move-out-cleaning">Move-in/Move-out Cleaning</Link></li>
        <li><Link to="/airbnb-cleaning">AirBnB Cleaning</Link></li>
    </ul>
</div>

<div className="address">
        <img id="starsingle" src={location} alt="location" />
        <span>102-25 185th St</span>
        <span>11423</span>
        <span>Hollis</span>
        <span>NY,11423</span>
        <span>United States</span>
</div>

<div className="contactfooter">
<img id="starsingle" src={contactmail} alt="contactmail" />
<span>info@harielscleaningco.com</span>
<span> <a href="tel:929-261-2976">+(929)-261-2976</a></span>
</div>
</section>
      
      </div>
        
    );
  }

export default Contact